import React, { useEffect, useState, Suspense, lazy } from "react";
import cs from "./showcase.module.css";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

export default function Template() {
    let [updateHead, setUpdateHead] = useState('');
    let [updateTemp, setUpdateTemp] = useState('');
    let [updateStats, setUpdateStats] = useState('');
    let [updateStatimg, setUpdateStatimg] = useState('');
    let [lightBox, setLightBox] = useState('');
    let [closeUpdate, setCloseUpdate] = useState(false);
    let uselocation = useLocation();
    let currentpathCategory = uselocation.pathname.split('/')[3].toLowerCase();
    let currentpathId = uselocation.pathname.split('/')[2];

    const [mainData, setMainData] = useState([]);
    const [tempData, setTempData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://beta.campaigntemplate.com/index.php?table=${currentpathCategory}contents`);
                setTempData(response.data);
            } catch (error) {
                console.log("Error fetching temp data:", error);
            }
        };
        fetchData();
    }, [currentpathCategory]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://beta.campaigntemplate.com/index.php?table=${currentpathCategory}`);
                setMainData(response.data);
            } catch (error) {
                console.log("Error fetching main data:", error);
            }
        };
        fetchData();
    }, [currentpathCategory]);

    let currentData = mainData.find((v) => v.id === currentpathId);
    let logo = currentData ? currentData.logo : '';
    let analy1 = currentData ? currentData.analy1 : '';
    let analy2 = currentData ? currentData.analy2 : '';
    let currentTempData = tempData.filter((v) => v.template_id === currentpathId);
    let firstData = currentTempData.length > 0 ? currentTempData[0] : '';

    let lightboxContent = document.querySelector("#lightboxContent");

    let closeBtn = () => {
        setCloseUpdate(false);
        if (lightboxContent) {
            lightboxContent.style.width = "450px";
        }
    };

    let plusBtn = ()=>{
        let width = lightboxContent.clientWidth;
        lightboxContent.style.width = (width + 20) + "px";
    }

    let minusBtn = () => {
        if (lightboxContent) {
            let width = lightboxContent.clientWidth;
            lightboxContent.style.width = (width - 20) + "px";
        }
    }

    return (
        <div className="max-w-[100%] relative md:h-full h-auto p-4 flex-wrap flex justify-center gap-y-5 gap-x-7 bg-[#f5f9fc]">
            <div className={`lg:max-w-[260px] bg-white w-[100%] flex justify-between flex-col items-center md:h-screen h-auto lg:sticky top-5 md:rounded-lg rounded-md ${cs.btnSec}`}>
                <div className="flex w-[100%] justify-center flex-col items-start">
                    <div className="w-[100%] h-auto flex justify-start items-start border-b-2 pb-6 border-blue-200 rounded-md">
                        <img src={logo} className="w-auto h-[50px]" alt="logo" />
                    </div>
                    {currentTempData.map((v, i) => (
                        <div key={i} className="w-full">
                            <div onClick={() => {
                                setUpdateHead(v.head);
                                setUpdateTemp(v.temp);
                                setUpdateStats(v.id);
                                setUpdateStatimg(v.stats);
                            }} className={`${updateStats === v.id ? `${cs.button1}` : `${cs.button}`} md:w-[200px] w-full`}>
                                <p className={`${updateStats === v.id ? `${cs.redColor}` : ``} text-ellipsis whitespace-nowrap w-[90%] overflow-hidden text-left`}>{v.head}</p>
                                <p className={`${updateStats === v.id ? `${cs.arrowup}` : `${cs.arrowdown}`}`}>&#x2B9D;</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={cs.btnBack}>
                    <Link to={'/'}>
                    <div className={`${cs.button2} w-[40%] bg-blue-700`}>
                        <p className="text-m">Back</p>
                    </div>
                    </Link>
                    
                </div>
            </div>
            <div className={`max-w-[620px] w-[100%] bg-white md:my-[30px] md:rounded-lg rounded-md ${cs.btnSec}`}>
                <div className={`w-full bg-gray-100 rounded-lg border-l-4 border-indigo-600`}>
                    <div className={`w-full p-6`}>
                        <h1 className="text-left text-gray-600 md:text-xl text-md font-bold md:text-ellipsis md:whitespace-nowrap w-[95%] overflow-hidden">{updateHead === "" ? firstData.head : updateHead}</h1>
                        <h2 className="text-left text-gray-400 text-md mt-2">Emailer | {currentpathCategory[0].toUpperCase() + currentpathCategory.substring(1)}</h2>
                    </div>
                </div>
                <img loading="lazy" onClick={() => { setLightBox(updateStatimg === "" ? firstData.stats : updateStatimg); setCloseUpdate(true) }} className="rounded shadow-md mt-6 w-full cursor-zoom-in" src={updateStatimg === "" ? firstData.stats : updateStatimg} alt="" />
                <img loading="lazy" src={analy1} onClick={() => { setLightBox(analy1); setCloseUpdate(true) }} className="shadow-md mt-6 w-full cursor-zoom-in" alt="Analytics" />
                <img loading="lazy" src={analy2} onClick={() => { setLightBox(analy2); setCloseUpdate(true) }} className="shadow-md mt-6 w-full cursor-zoom-in" alt="Analytics" />
            </div>
            <div className={`max-w-[355px] w-[100%] md:h-full h-auto md:rounded-lg rounded-md sticky top-5 bg-white ${cs.btnSec}`}>
                <h1 className="text-left text-black font-bold text-lg mb-6">Email Template</h1>
                <img loading="lazy" onClick={() => { setLightBox(updateTemp === "" ? firstData.temp : updateTemp); setCloseUpdate(true) }} className="rounded shadow-md cursor-zoom-in" src={updateTemp === "" ? firstData.temp : updateTemp} alt="" />
            </div>
            <div className={`fixed w-full h-full flex bg-black bg-opacity-90 z-10 top-0 right-0 justify-center items-center ${closeUpdate == true ? "block" : "hidden"}`}>
                <div className="relative w-full h-[100%] box-border pt-4 flex overflow-y-auto justify-start flex-col items-center">
                    <div className="w-[550px] flex justify-center items-start h-full" id="lightboxContent">
                        <img src={lightBox}  className="w-full h-auto rounded-lg" alt=""/>
                    </div>
                    <div className="flex justify-end fixed bottom-4 right-8 items-center w-full gap-4">
                        <button onClick={minusBtn} className=" text-white bg-red-800 text-[20px] rounded-sm outline-none border-none cursor-pointer px-3">&#45;</button>
                        <button onClick={plusBtn} className=" text-white bg-green-800 text-[20px] rounded-sm outline-none border-none cursor-pointer px-3">&#43;</button>
                    </div>
                    <button onClick={closeBtn} className="fixed top-4 right-8 bg-white text-blue-950 text-lg rounded-sm outline-none border-none cursor-pointer px-2">&#10799;</button>
                </div>
            </div>
        </div>
    );
}
